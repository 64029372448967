<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import References from "../components/References/References.svelte";
	import HomepageBanner from "../components/HomepageBanner.svelte";
	import Services from "../components/Services.svelte";
	import HelpSection from "../components/HelpSection.svelte";
	import PodcastSection from "../components/PodcastSection.svelte";
	import BlogPosts from "../components/BlogPosts.svelte";
	import Conferences from "../components/Conferences.svelte";
	import Contact from "../components/Contact.svelte";
	import type { HomepageProps } from "./HomepageProps.js";
	import { nbspify } from "../../../core/utils/nbspify";

	type $$Props = HomepageProps;

	const { references, blogPosts, podcasts } = $$restProps as $$Props;
</script>

<Layout noPaddingTop>
	<HomepageBanner />
	<div class="mt-20 flex flex-col gap-20 lg:mt-32 lg:gap-32">
		<Services />
	</div>
	<div class="container flex flex-col gap-20 lg:mt-32 lg:gap-32">
		<References {references} />
	</div>
	<div class="my-20 lg:my-32">
		<HelpSection />
	</div>
	<div class="container flex flex-col gap-20 lg:mt-32 lg:gap-32">
		<PodcastSection {podcasts} />
		<BlogPosts {blogPosts}>
			<p class="text-bito-black text-center font-extrabold" slot="perex">
				{nbspify("Není sklad jako sklad. Kdo skladuje efektivně, dodává rychle a bez chyb.")}
				<br />
				{nbspify(" Má tak jasnou výhodu nad svou konkurencí – a o tom blogujeme!")}
			</p>
		</BlogPosts>
	</div>
	<Conferences />
	<Contact />
</Layout>
